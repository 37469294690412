import { createContext, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import "./App.scss";
import Login from './pages/Login'
import Home from './pages/Home'
import Prize from './pages/Prize';
import Scanner from './pages/Scanner';
import ScannerSummary from './pages/ScannerSummary';

const DataContext = createContext()

let urlName = window.location.pathname
let localStorageCurrentPage = localStorage.getItem('currentPage')

let currentPageName = '/home'

if(!!localStorageCurrentPage) {
  if(urlName === ('/')) {  currentPageName = localStorageCurrentPage } else { currentPageName = urlName }
} else {
  if(urlName === ('/')) { currentPageName = '/home' } else { currentPageName = urlName }
}

// let localStorageCurrentPage = localStorage.getItem('currentPage')
// console.log('urlName', urlName)

// let currentPageName = '/home'

// // Check pathname
// if((urlName === '/' && !localStorage.getItem('currentPage') ) || !urlName || !localStorage.getItem('currentPage') || localStorage.getItem('currentPage') === '/') {
//   currentPageName = '/home'

// } else {
  
//   if(urlName === localStorageCurrentPage) {
//     currentPageName = localStorage.getItem('currentPage')
//   } else {

//     if(urlName === '/') {
//       currentPageName = '/home'
//     } else {
//       currentPageName = urlName
//     }

//   }

// }

// let currentPageName = ((urlName === '/' && !localStorage.getItem('currentPage') ) || !urlName || !localStorage.getItem('currentPage') || localStorage.getItem('currentPage') === '/') ? '/home' : (localStorage.getItem('currentPage') === urlName) ? localStorage.getItem('currentPage') : urlName
// let getCurrentPage = urlName

// Check localStorage has 'currentPage'
localStorage.setItem('currentPage', currentPageName)


function App() {
  // const [token, setToken] = useState('')
  const [dataLogin, setDataLogin] = useState('')
  const [dataLoginStatus, setDataLoginStatus] = useState(0)
  const [dataLoginStatusMsg, setDataLoginStatusMsg] = useState(0)
  const [currentPage, setCurrentPage] = useState(currentPageName)
  
  return (
    <DataContext.Provider value={{ /*token, setToken, */ dataLogin,setDataLogin, dataLoginStatus, setDataLoginStatus, dataLoginStatusMsg, setDataLoginStatusMsg, currentPage, setCurrentPage }}>
      <div className="App container  max-w-3xl mx-auto bg-bg-pattern overflow-hidden">
            <BrowserRouter>
              <Routes>
                {['/','home', 'prize', 'scanner', 'scanner-summary'].map((path,i) => <Route key={i} path={path} element={<Login />} />)}
                {/* <Route path="/" element={<Login />} />
                <Route path="home" element={<Login />} />
                <Route path="prize" element={<Login />} />
                <Route path="scanner" element={<Login />} />
                <Route path="scanner-summary" element={<Login />} /> */}


                <Route path="home-page" element={<Home />} />
                <Route path="prize-page" element={<Prize />} />
                <Route path="scanner-page" element={<Scanner />} />
                <Route path="scanner-summary-page" element={<ScannerSummary />} />
              </Routes>
            </BrowserRouter>
      </div>
    </DataContext.Provider>
  );
}

export { DataContext };
export default App;
