import React, { useRef, useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Iframe from 'react-iframe'

import { DataContext } from '../App'

import IconBack from '../assets/img/ic-back.svg'

function Prize() {
    const navigate = useNavigate()
    const footerRef = useRef(null)
    const { setCurrentPage } = useContext(DataContext)
    const [footerHeight, setFooterHeight] = useState(92)
    // const [campaignID, setCampaignID] = useState(0)
    const [firstCampaignID, setFirstCampaignID] = useState(0)
    const [secondCampaignID, setSecondCampaignID] = useState(0)
    const [thirdCampaignID, setThirdCampaignID] = useState(0)
    const [queryValue, setQueryValue] = useState('')
    const [scanUrl, setScanUrl] = useState('')
    const [scanSummaryUrl, setScanSummaryUrl] = useState('')
    const [name, setName] = useState('ผู้ใช้งาน')
    const [campaignAmount, setCampaignAmount] = useState(1)

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const getDataQuery = localStorage.getItem('dataQuery')
        
        // Check query for reload page
        if(urlParams.get('query') === getDataQuery) {
            navigate('/')
        }

console.log('/prize',window.location)

        // if(urlParams.has('campaign_id')) {
        //     setCampaignID(Number(urlParams.get('campaign_id')))
        // }
        if(urlParams.has('amount_campaign')) {
            console.log(Number(urlParams.get('amount_campaign')))
            setCampaignAmount(Number(urlParams.get('amount_campaign')))
        }

        if(urlParams.has('first_campaign_id')) {
            setFirstCampaignID(Number(urlParams.get('first_campaign_id')))
        }
        if(urlParams.has('second_campaign_id')) {
            setSecondCampaignID(Number(urlParams.get('second_campaign_id')))
        }
        if(urlParams.has('third_campaign_id')) {
            setThirdCampaignID(Number(urlParams.get('third_campaign_id')))
        }

        if(urlParams.has('query')) {
            setQueryValue(urlParams.get('query'))
            localStorage.setItem('dataQuery', urlParams.get('query'))
        }

        if(urlParams.has('scan_url')) {
            setScanUrl(urlParams.get('scan_url'))
        }

        if(urlParams.has('scan_summary_url')) {
            setScanSummaryUrl(urlParams.get('scan_summary_url'))
        }

        if(urlParams.has('name')) {
            setName(urlParams.get('name'))
        }

        setCurrentPage('/prize')
        setFooterHeight(footerRef.current?.clientHeight + 120)
    
      return () => {
        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentPage])

    return (
        <div className="prize-page">
            <div className="header fixed z-10 top-0 bg-[linear-gradient(180deg,_#ffa000,_#e3821f)] p-4 flex w-full h-14 max-w-3xl">
                <div className="absolute p-4 pl-1 top-0 left-0 z-20 flex gap-1" onClick={()=> { setCurrentPage('/home'); /* localStorage.setItem('currentPage','/home')*/; navigate(`/home-page?query=${queryValue}&amount_campaign=${campaignAmount}&first_campaign_id=${firstCampaignID}&second_campaign_id=${secondCampaignID}&third_campaign_id=${thirdCampaignID}&name=${name}&scan_url=${encodeURIComponent(scanUrl)}&scan_summary_url=${encodeURIComponent(scanSummaryUrl)}`); }}>
                    <img src={IconBack} alt="Logo Vip" />
                    <p className="text-white font-promptregular">กลับ</p>
                </div>
                <p className="text-white w-full text-center">สวัสดี { name }</p>
            </div>

            <div className="px-4 pb-4 pt-20 mb-48 h-screen" style={{marginBottom: footerHeight}}>
                {/* <BtnPrimary text="Logout" action={()=> { liff.logout(); navigate('/'); }} /> */}
                {
                    !!queryValue ?
                        <Iframe url={`${process.env.REACT_APP_PRIZE_URL}/?query=${queryValue}`}
                            width="100%"
                            height="100%"
                            id="progressbar"
                            className=""
                            display="block"
                            position="relative"
                            sandbox={["allow-scripts", "allow-same-origin"]}
                            styles={{height: "100vh"}}
                        />
                    :
                        <p className="py-8 font-bold text-2xl text-center w-full">คุณยังไม่มีแคมเปญในขณะนี้</p>
                }
            </div>

            <div ref={footerRef} className="footer fixed bottom-0 max-w-3xl">
                <div className="bg-white py-4 px-2 text-2xs xs:text-3xs text-center font-promptregular">สำหรับสื่อสารกับผู้ค้าที่มีใบอนุญาตขายผลิตภัณฑ์ยาสูบอาศัยอยู่ในประเทศไทยเท่านั้น"ผลิตภัณฑ์ยาสูบเป็นสารเสพติดที่ทำลายสุขภาพ</div>
            </div>
        </div>
    )
}

export default Prize