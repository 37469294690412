import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../App'

function Scanner() {
    const navigate = useNavigate()
    const { dataLogin, dataLoginStatus, setCurrentPage } = useContext(DataContext)
    const [scanUrl, /*setScanUrl*/] = useState('')

    useEffect(() => {

        window.parent.location = (!!scanUrl) ? decodeURIComponent(scanUrl) : dataLogin?.scanner?.scan_url+"&openExternalBrowser=1";
        
        setCurrentPage('/scanner')
        localStorage.setItem('currentPage','/scanner')

        return () => {
            
        }
    }, [dataLogin, dataLoginStatus, navigate, scanUrl, setCurrentPage])
    

    return (
        <div className="w-full h-screen flex justify-center items-center">
            ...Loading Scanner...
        </div>
    )
}

export default Scanner