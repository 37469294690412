import React, { useRef, useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { /*BtnPrimary,*/ BtnSecondary } from '../components/ItemButton';
import Iframe from 'react-iframe'

import { DataContext } from '../App'

import Logo from '../assets/img/logo-vip.svg'
import IconWallet from '../assets/img/ic-wallet.svg'
import IconScan from '../assets/img/ic-scan.svg'

function Home() {
    const navigate = useNavigate()
    const footerRef = useRef(null)
    const { setCurrentPage } = useContext(DataContext)
    const [footerHeight, setFooterHeight] = useState(180)
    // const [campaignID, setCampaignID] = useState(0)
    const [firstCampaignID, setFirstCampaignID] = useState(0)
    const [secondCampaignID, setSecondCampaignID] = useState(0)
    const [thirdCampaignID, setThirdCampaignID] = useState(0)
    const [queryValue, setQueryValue] = useState('')
    const [scanUrl, setScanUrl] = useState('')
    const [scanSummaryUrl, setScanSummaryUrl] = useState('')
    const [name, setName] = useState('ผู้ใช้งาน')
    const [campaignAmount, setCampaignAmount] = useState(1)

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const getDataQuery = localStorage.getItem('dataQuery')
        
        // Check query for reload page
        if(urlParams.get('query') === getDataQuery) {
            navigate('/')
        }

console.log('/home',window.location)
        // if(urlParams.has('campaign_id')) {
        //     setCampaignID(Number(urlParams.get('campaign_id')))
        // }
        if(urlParams.has('amount_campaign')) {
            setCampaignAmount(Number(urlParams.get('amount_campaign')))
        }

        if(urlParams.has('first_campaign_id')) {
            setFirstCampaignID(Number(urlParams.get('first_campaign_id')))
        }
        if(urlParams.has('second_campaign_id')) {
            setSecondCampaignID(Number(urlParams.get('second_campaign_id')))
            // if( Number(urlParams.get('second_campaign_id')) ){
            //     setCampaignAmount(2)
            // }
        }
        if(urlParams.has('third_campaign_id')) {
            setThirdCampaignID(Number(urlParams.get('third_campaign_id')))
        }
        if(urlParams.has('query')) {
            setQueryValue(urlParams.get('query'))
            localStorage.setItem('dataQuery', urlParams.get('query'))
        }

        if(urlParams.has('scan_url')) {
            setScanUrl(urlParams.get('scan_url'))
        }

        if(urlParams.has('scan_summary_url')) {
            setScanSummaryUrl(urlParams.get('scan_summary_url'))
        }

        if(urlParams.has('name')) {
            setName(urlParams.get('name'))
        }

        setCurrentPage('/home')
        setFooterHeight(footerRef.current?.clientHeight + 40)

        // setScreenHeight(iframeFirst.current?.clientHeight + iframeSecond.current?.clientHeight + 20)
    
      return () => {
        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentPage])

    const openExternalScanner = () => {
        window.parent.location = decodeURIComponent(scanUrl)
    }
    

    return (
        <div className="home-page h-screen overflow-auto">
            <div className="header fixed z-10 top-0 bg-[linear-gradient(180deg,_#ffa000,_#e3821f)] p-4 flex w-full h-14 max-w-3xl">
                <div className="absolute left-5 top-2">
                    <img src={Logo} alt="Logo Vip" />
                </div>
                <p className="text-white w-full text-center">สวัสดี { name }</p>
            </div>

            {/* for calculate amount and height of iframe */}
            <div className="px-4 pb-4 pt-20 mb-48 h-screen" style={{
                // marginBottom: (campaignAmount === 2) ? footerHeight : (campaignAmount === 1) ? (footerHeight + (!!secondCampaignID ? 350 : 0)) : 0 + 'px', 
                // height: ((window.innerHeight * (!!campaignAmount ? campaignAmount : 1)) + (campaignAmount === 2 ? 250 : 0) ) + 'px'
                // height: (window.innerHeight * (!!campaignAmount ? campaignAmount : 1) ) + ((campaignAmount === 2) ? footerHeight + 40 : 0) + 'px'
                marginBottom: 180+'px'
            }}>
                {/* <BtnPrimary text="Logout" action={()=> { liff.logout(); navigate('/'); }} /> */}

                {/* check amount campaign */}
                {
                    !firstCampaignID && !secondCampaignID && !thirdCampaignID ? <div className="h-full flex items-center justify-center"><p style={{marginBottom: '35%', fontSize: '1.4rem'}}>คุณยังไม่มีแคมเปญ</p></div> : <p className="mb-2 font-bold text-2xl">เป้าสะสมจากการสแกนบุหรี่</p>
                }
                {
                    !!queryValue && !!firstCampaignID  ?
                    <>
                        <Iframe url={`${process.env.REACT_APP_PROGRESSBAR_URL}/${firstCampaignID}?query=${queryValue}`}
                            width="100%"
                            // height={(window.innerHeight - 100)+'px'}
                            height={(window.innerHeight + 266)+'px'}
                            id="progressbar-1"
                            className="iframe border"
                            display="block"
                            position="relative"
                            sandbox={["allow-scripts", "allow-same-origin", "allow-top-navigation"]}
                            // styles={{ border: '1px solid #cdcdcd'}}
                            styles={{ border: '1px solid #cdcdcd', maxHeight: '962px'}}
                        />
                        <br/>
                    </>
                    : null // <p style={{height: (window.innerHeight - 100)+'px'}} className="py-8 font-bold text-2xl text-center w-full flex items-center justify-center">คุณยังไม่มีแคมเปญในขณะนี้</p>
                }

                {
                    !!queryValue && !!secondCampaignID ?
                    <>
                        <Iframe url={`${process.env.REACT_APP_STAMP_URL}/${secondCampaignID}?query=${queryValue}`}
                            width="100%"
                            height={(window.innerHeight + 226)+'px'}
                            id="progressbar-2"
                            className="iframe border"
                            display="block"
                            position="relative"
                            sandbox={["allow-scripts", "allow-same-origin", "allow-top-navigation"]}
                            styles={{ border: '1px solid #cdcdcd', maxHeight: '962px'}}
                        />
                        <br/>
                    </>
                    : null // <p style={{height: (window.innerHeight - 100)+'px'}} className="py-8 font-bold text-2xl text-center w-full flex items-center justify-center">คุณยังไม่มีแคมเปญในขณะนี้</p>
                }

{
                    !!queryValue && !!thirdCampaignID ?
                    <>
                        <Iframe url={`${process.env.REACT_APP_LEADERBOARD_URL}/${thirdCampaignID}?query=${queryValue}`}
                            width="100%"
                            height={(window.innerHeight + 226)+'px'}
                            id="progressbar-3"
                            className="iframe border"
                            display="block"
                            position="relative"
                            sandbox={["allow-scripts", "allow-same-origin", "allow-top-navigation"]}
                            styles={{ border: '1px solid #cdcdcd', maxHeight: '962px'}}
                        />
                    </>
                    : null // <p style={{height: (window.innerHeight - 100)+'px'}} className="py-8 font-bold text-2xl text-center w-full flex items-center justify-center">คุณยังไม่มีแคมเปญในขณะนี้</p>
                }
                <div className="gap-box" style={{width: 100+'%',height: footerHeight +'px'}}></div>
            </div>

            <div ref={footerRef} className="footer fixed bottom-0 max-w-3xl">
                <div className="p-4 bg-opacity-20 bg-black flex gap-4">
                    <BtnSecondary text="กระเป๋ารางวัล" icon={IconWallet} action={()=> { navigate(`/prize-page?query=${queryValue}&amount_campaign=${campaignAmount}&first_campaign_id=${firstCampaignID}&second_campaign_id=${secondCampaignID}&third_campaign_id=${thirdCampaignID}&name=${name}&scan_url=${encodeURIComponent(scanUrl)}&scan_summary_url=${encodeURIComponent(scanSummaryUrl)}`) }} addclass={'xs:text-3xs'} />
                    <BtnSecondary text="สแกนบุหรี่" icon={IconScan} action={()=> { openExternalScanner() }} addclass={'xs:text-3xs'} />
                </div>
                <div className="bg-white py-4 px-2 text-2xs xs:text-3xs text-center font-promptregular">สำหรับสื่อสารกับผู้ค้าที่มีใบอนุญาตขายผลิตภัณฑ์ยาสูบอาศัยอยู่ในประเทศไทยเท่านั้น"ผลิตภัณฑ์ยาสูบเป็นสารเสพติดที่ทำลายสุขภาพ</div>
            </div>
        </div>
    )
}

export default Home