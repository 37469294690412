import React, { useEffect, useState, useCallback, useContext } from 'react'
import Modal from 'react-modal'
import { BtnPrimary } from '../components/ItemButton';
import liff from '@line/liff';
import Loading from '../components/Loading';
import { DataContext } from '../App';

// const mockupData = {
//     "name": "บิ๊กอาร์ท - 1 ",
//     "query": "NPJWT-eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25fcGFuZWwiOjEsImlzbXNfY29kZSI6IjEwMDAwMDAiLCJjb250YWN0X2lkIjpudWxsLCJub25fcGFuZWxfaWQiOjQwLCJpYXQiOjE3MDIxODcyNTQsImV4cCI6MTcwMjI3MzY1NH0.eRQzZzviMhWn0E5bK9W_TrzfufL3ISKuIZ2l8Kiq1zs",
//     "progressbar": {
//         "campaign": [
//             {
//                 "id": 126,
//                 "name": "Ake test Non-Panel",
//                 "type": "stamp"
//             }
//             // ,{
//             //     "id": 130,
//             //     "name": "test create stamp2",
//             //     "type": "stamp"
//             // }
//             // ,{
//             //     "id": 128,
//             //     "name": "test create stamp3",
//             //     "type": "stamp"
//             // }
//             ,{
//                 "id": 132,
//                 "name": "test create Barcode",
//                 "type": "barcode"
//             } 
//             // ,{
//             //     "id": 131,
//             //     "name": "test create Barcode2",
//             //     "type": "barcode"
//             // }
//             ,{
//                 "id": 155,
//                 "name": "test create LeaderBoard",
//                 "type": "leaderboard"
//             } 
//         ]
//     },
//     "scanner": {
//         "scan_url": "https://v2.pmiandyou.com/test/test_front/barcode/scanner-2020/redirect?uid=null&cid=ISMS1000000&fn=%E0%B8%9A%E0%B8%B4%E0%B9%8A%E0%B8%81%E0%B8%AD%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%97%20-%201%20&ln=null&em=null&ssid=false&trdprm=null&rstyp=null&tpid=null&ad=NON-PANEL&rsdct=null&isms=1000000&back=https%3A%2F%2Fliff.access-sandbox.vivi-th.com%2Fscanner-summary&home=https%3A%2F%2Fliff.access-sandbox.vivi-th.com&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaWQiOiJJU01TMTAwMDAwMCIsImlzbXMiOiIxMDAwMDAwIiwidHJkcHJtIjoibnVsbCIsInJzdHlwIjoibnVsbCIsImlhdCI6MTcwMjE4NzI1NCwiZXhwIjoxNzAyMjczNjU0fQ.rPltjbzHD55kAFg7KiE4KHctUyV5r8_8EQtH2FNYzd4",
//         "summary_url": "https://v2.pmiandyou.com/test/test_front/barcode/scanner-2020/nonpanel?uid=null&cid=ISMS1000000&fn=%E0%B8%9A%E0%B8%B4%E0%B9%8A%E0%B8%81%E0%B8%AD%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%97%20-%201%20&ln=null&em=null&ssid=false&trdprm=null&rstyp=null&tpid=null&ad=NON-PANEL&rsdct=null&isms=1000000&back=https%3A%2F%2Fliff.access-sandbox.vivi-th.com%2Fscanner-summary&home=https%3A%2F%2Fliff.access-sandbox.vivi-th.com&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaWQiOiJJU01TMTAwMDAwMCIsImlzbXMiOiIxMDAwMDAwIiwidHJkcHJtIjoibnVsbCIsInJzdHlwIjoibnVsbCIsImlhdCI6MTcwMjE4NzI1NCwiZXhwIjoxNzAyMjczNjU0fQ.rPltjbzHD55kAFg7KiE4KHctUyV5r8_8EQtH2FNYzd4"
//     },
//     "prize": {}
// }

function Login() {
    const { currentPage, setDataLogin, setDataLoginStatus, setDataLoginStatusMsg} = useContext(DataContext)
    const [hasError, setHasError] = useState(false)
    const [msgError, setMsgError] = useState('เกิดข้อผิดพลาด')
    const [modalError, setModalError] = useState(false)
    const [msgModalError, setMsgModalError] = useState('ไม่สามารถทำรายการได้')
    const [hasErrorType, setHasErrorType] = useState(0)

    const fetchLogin = useCallback( async () => {
        const liffAccessToken = liff.getAccessToken();
        
        try {
                
            const resLogin = await fetch(`${process.env.REACT_APP_API_URL}/liff/api/nonpanel/login`, {
                method: 'POST',
                body: JSON.stringify({
                    "line_access_token": liffAccessToken
                }),
                headers: {
                    "x-application-secret-key": 'b5e64ba6-1d27-4c40-bc65-2e686e394d62',
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    // 'Authorization': 'Bearer ' + accesstokenValue
                }
                });
                
                if(!resLogin.ok) {
                    return resLogin.text().then(text => { 
                        if(resLogin.status === 401) {
                            if(text === 'Unauthorized wrong group') {
                                setHasError(false)
                                setMsgError(`เกิดข้อผิดพลาด`)
                                setMsgModalError(`กรุณาเข้าระบบ VIP เพื่อใช้งาน`)
                                setHasErrorType(1)
                            } else {
                                setHasError(true)
                                setMsgError(`คุณยังไม่มีสิทธิ์เข้าใช้งานระบบ กรุณาติดต่อเจ้าหน้าที่`)
                                setMsgModalError(`คุณยังไม่มีสิทธิ์เข้าใช้งานระบบ กรุณาติดต่อเจ้าหน้าที่`)
                                setHasErrorType(0)
                            }
                            
                        } else {
                            setMsgModalError(`Error[${resLogin.status}] : ${text}`)
                            setHasErrorType(0)
                        }
                        setModalError(true)
                    })
                } else {
                    resLogin
                    .json()
                    .then(res => {
                        console.log('resLogin:', res)
                        console.log('resLoginStatus:', resLogin.status)
                        // let responseData = mockupData // for test
                        let responseData = res
                        setDataLogin(res)
                        setDataLoginStatus(resLogin.status)
        
                        console.log('currentPage', currentPage)

                        let hrefCurrentPage
                        if(currentPage.includes('-page')) {
                            hrefCurrentPage = currentPage
                        } else {
                            hrefCurrentPage = currentPage+'-page'
                        }
        
                        const { userAgent } = navigator
                        if(currentPage === '/scanner') {
                            // Check LIFF in-app browser
                            if(userAgent.includes('Line')) {
                                liff.openWindow({
                                    url: res.scanner.scan_url+`&openExternalBrowser=1`,
                                    external: true,
                                });
                            } else {
                                window.parent.location = res.scanner.scan_url
                            }
                        } else {
                            // Loop for get each type
                            let pbStampArr = []
                            let pbBarcodeArr = []
                            let pbLeaderboardArr = []

                            if(!!responseData.progressbar?.campaign.length) {
                                for(let i=0; i<responseData.progressbar?.campaign.length; i++) {
                                    if(responseData.progressbar?.campaign[i].type === 'stamp') {
                                        pbStampArr.push(responseData.progressbar?.campaign[i])
                                    } else if(responseData.progressbar?.campaign[i].type === 'barcode') {
                                        pbBarcodeArr.push(responseData.progressbar?.campaign[i])
                                    } else {
                                        pbLeaderboardArr.push(responseData.progressbar?.campaign[i])
                                    }
    
                                    console.log(responseData.progressbar?.campaign[i])
                                }
                            }

                            let pbStampArrId = ( pbStampArr.length === 0 ? 0 : pbStampArr[0].id ).toString()
                            let pbBarcodeArrId = ( pbBarcodeArr.length === 0 ? 0 : pbBarcodeArr[0].id ).toString()
                            let pbLeaderboardArrId = ( pbLeaderboardArr.length === 0 ? 0 : pbLeaderboardArr[0].id ).toString()

                            // for check amount campaign 
                            // (if pbStampArr.length and pbBarcodeArr.length has value set 2, 
                            // if pbStampArr.length or pbBarcodeArr.length hasn't value set 1, 
                            // othe value set 0)
                            let amountCampaignId = (!!pbStampArr.length && !!pbBarcodeArr.length) ? 2 : (!pbStampArr.length && !!pbBarcodeArr.length) || (!!pbStampArr.length && !pbBarcodeArr.length) ? 1 : 0

                            // Check LIFF in-app browser
                            if(userAgent.includes('Line')) {
                                liff.openWindow({
                                    url: process.env.REACT_APP_WEB_URL+`${hrefCurrentPage}?query=${responseData.query}&name=${responseData.name}&amount_campaign=${amountCampaignId}&first_campaign_id=${pbBarcodeArrId}&second_campaign_id=${pbStampArrId}&third_campaign_id=${pbLeaderboardArrId}&scan_url=${encodeURIComponent(responseData.scanner?.scan_url)}&scan_summary_url=${encodeURIComponent(responseData.scanner?.summary_url)}&openExternalBrowser=1`,
                                    external: true,
                                });
                            } else {
                                window.parent.location = process.env.REACT_APP_WEB_URL+`${hrefCurrentPage}?query=${responseData.query}&name=${responseData.name}&amount_campaign=${amountCampaignId}&first_campaign_id=${pbBarcodeArrId}&second_campaign_id=${pbStampArrId}&third_campaign_id=${pbLeaderboardArrId}&scan_url=${encodeURIComponent(responseData.scanner?.scan_url)}&scan_summary_url=${encodeURIComponent(responseData.scanner?.summary_url)}`
                            }
                        }
                        
                        setTimeout(() => {
                            liff.closeWindow()
                            localStorage.removeItem('currentPage')
                        }, 2000);
                    })
                    .catch(err => {
                        console.log('[Error]:',err);
                        if(resLogin.status === 400 && err === 'Multiple users with ISMS code found') {
                            setDataLoginStatus(resLogin.status)
                            setDataLoginStatusMsg(resLogin.message)
                        } else {
                            setHasError(true)
                            setMsgError(err.toString())
                        }
                    });
                }
        
        } catch (error) {
            setModalError(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[],)

    Modal.setAppElement('#root');

    useEffect(() => {
        // Fixbug "Failed to fetch"
        const originalFetch = window.fetch
        function customFetch(url, options) {
        if (
            url.toString().startsWith("https://liffsdk.line-scdn.net/xlt/") &&
            url.toString().endsWith(".json")
        ) {
            url = url + "?ts=" + Math.random()
        }
        return originalFetch(url, options)
        }
        window.fetch = customFetch

        // Check LIFF in-app browser
        // const { userAgent } = navigator
        // if(userAgent.includes('Line')) {
            liff.init({
                liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
                withLoginOnExternalBrowser: true
            })
            .then(() => {
                if( !liff.isLoggedIn() ) {
                    liff.login()
                } else {   
                    fetchLogin()
                }
            })
            .catch((err) => {
                console.log(err.toString());
                setHasError(true)
                setMsgError('[LIFF] '+err.toString())
            });
        // } else {
        //     setHasError(true)
        //     setMsgError('กรุณาเข้าระบบผ่าน LINE OA')
        // }
    
        return () => {
            
        }
    }, [fetchLogin])
    

    return (
        <div className="login h-screen flex flex-col items-center bg-[linear-gradient(180deg,_#ffa000,_#e3821f)]">
            <Loading status="LiffisNotLoggedIn" color="text-secondary" />
            {
                hasError ? 
                    <p className="mb-4 text-[12px] text-white font-promptregular">{ msgError }</p>
                : null
            }

            {/* Modal Error */}
            <Modal
                isOpen={modalError}
                className="Modal"
                overlayClassName="Overlay"
            >
                <h1 className="mb-5 text-xl">{ msgModalError }</h1>
                {
                    hasErrorType ? 
                        <BtnPrimary type="button" text="ตกลง" action={()=> { window.location.href = 'https://liff.line.me/1615041641-0OQlYBLb' }} />
                    :
                        <BtnPrimary type="button" text="ตกลง" action={()=> { setModalError(false); }} />
                }
            </Modal>
        </div>
    )
}

export default Login